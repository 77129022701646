import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="question-form"
export default class extends Controller {
  static targets = ["optionsSection", "answerType"]

  connect() {
    console.log("QuestionFormController connected")
    if (this.answerTypeTarget.value) {
      this.toggleOptionsSection()
    }
  }

  toggleOptionsSection() {
    const showOptions = ["multiple_choice", "checkbox", "scale"].includes(this.answerTypeTarget.value)
    if (showOptions) {
      this.optionsSectionTarget.classList.remove("hidden")
    } else {
      this.optionsSectionTarget.classList.add("hidden")
    }
  }

  addOption(event) {
    event.preventDefault()
    const timestamp = new Date().getTime()
    const template = this.optionTemplate(timestamp)
    this.optionsSectionTarget.insertAdjacentHTML('beforeend', template)
  }

  removeOption(event) {
    event.preventDefault()
    const option = event.target.closest('.option-fields')
    option.remove()
  }

  optionTemplate(timestamp) {
    return `
      <div class="option-fields flex items-center gap-4 mt-2">
        <div class="flex-1">
          <input type="text" 
                 name="question[options_attributes][${timestamp}][content]" 
                 class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                 placeholder="Option text">
        </div>
        <div class="w-24">
          <input type="number" 
                 name="question[options_attributes][${timestamp}][value]"
                 class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                 placeholder="Value">
        </div>
        <button type="button" 
                data-action="question-form#removeOption" 
                class="text-red-600 hover:text-red-800">
          Remove
        </button>
      </div>
    `
  }
}
